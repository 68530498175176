import { useEffect, useMemo, useState } from "react";
import { Switch } from "@chakra-ui/react";

import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import WatchTutorial from "@/components/Common/WatchTutorial";
import SaveAndRunEnrichment from "@/components/Enrichment/Common/SaveAndRun";
import SelectColumnDropdown from "@/components/Enrichment/Common/SelectColumnDropdown";
import ConditionalFormula from "../../Common/ConditionalFormula";

import { useEnrichStore } from "@/stores/enrich.store";
import type { TSelectedColumnOption } from "@/types/common.types";
import { EnrichmentMetaData } from "@/types/table.types";
import { getInitialColumn, preselectEnrichmentState } from "@/utils";
import { REGEX } from "../../constants";
import AdvancedDetails from "../../Common/AdvancedDetails";
import { useTableStore } from "@/stores/table.store";
import { useTableInfo } from "@/hooks/useTableData";

interface EnrichmentDataType extends EnrichmentMetaData {
  isUseOwnKey: boolean;
  extraColumns?: string[];
  excludedJobTitles?: Option[];
  includedJobTitles?: Option[];
  isPrioritizeJobTitle?: boolean;
  companyNameId?: string;
}

const FindPeopleAtCompany2 = () => {
  const [selectedOption, setSelectedOption] =
    useState<TSelectedColumnOption | null>(null);
  const [includedJobTitles, setIncludedJobTitles] = useState<Option[] | []>([]);
  const [isAddToNewTable, setIsAddToNewTable] = useState(false);
  const [dependentTableId, setDependentTableId] = useState<string | null>(null);
  const [columnIdentifier, setColumnIdentifier] = useState<string>("");

  const viewMode = useEnrichStore((state) => state.viewMode);
  const tableData = useTableStore((state) => state.tableData);
  const linkedColumnIds = useMemo<string[]>(() => {
    return (
      tableData.columns
        ?.map((column) => {
          if (
            column?.metaData?.enrichmentMetaData?.enrichmentName ===
            "findPeopleAtCompany2"
          ) {
            return column?.metaData?.enrichmentMetaData?.dependentTableId;
          }
          return null;
        })
        .filter(Boolean) || []
    );
  }, [tableData]);
  const { data: tableInfos } = useTableInfo(linkedColumnIds);

  const tables = tableInfos
    ? Array.isArray(tableInfos.data)
      ? tableInfos.data
      : [tableInfos.data]
    : [];
  const selectedColumnToEdit = useEnrichStore(
    (state) => state.selectedColumnToEdit,
  );
  const updateState = useEnrichStore((state) => state.updateState);

  const updateJobTitlesState = (titles: string | string[], setState: any) => {
    if (Array.isArray(titles)) {
      setState(
        titles.map((item) => ({
          label: item,
          value: item,
        })),
      );
    } else {
      const jobTitles = titles.split(",");
      setState(
        jobTitles.map((item) => ({
          label: item,
          value: item,
        })),
      );
    }
  };

  useEffect(() => {
    if (tables?.length && isAddToNewTable) {
      setDependentTableId(tables?.[0]?._id);
    }
  }, [tables, isAddToNewTable]);

  useEffect(() => {
    if (viewMode === "edit") {
      const enrichmentData = selectedColumnToEdit?.metaData
        ?.enrichmentMetaData as EnrichmentDataType;
      if (enrichmentData) {
        setColumnIdentifier(enrichmentData.columnIdentifier || "");
        preselectEnrichmentState(enrichmentData, setSelectedOption);
        updateState({
          runConditionFormula: enrichmentData.runCondition,
          isInvalidConditionFormula: false,
          keySelect: "persanaApiKey",
        });
        setDependentTableId(enrichmentData.dependentTableId || null);

        updateJobTitlesState(
          // @ts-ignore
          enrichmentData.includedJobTitles || [],
          setIncludedJobTitles,
        );
      }

      return;
    }

    const initialColumn = getInitialColumn(REGEX.WEBSITE);
    if (initialColumn) {
      setSelectedOption({
        key: initialColumn.name,
        keyId: initialColumn._id,
        iconType: initialColumn.metaData?.iconType || "url",
      });
    }
  }, [viewMode, selectedColumnToEdit]);

  return (
    <>
      <div className="max-h-[70vh]  grow overflow-y-auto p-4">
        <WatchTutorial subText="Find People At Company by Website" link={""} />
        <div className="py-2">
          <div className="flex w-full items-center justify-between pb-1">
            <p className="pb-1 text-base font-semibold">Company Website</p>
            <div className="flex items-center space-x-2">
              <label
                htmlFor="add-to-new-table-FPAC"
                className="text-gray-400 text-base font-medium"
              >
                Add to new table
              </label>
              <Switch
                size={"md"}
                isChecked={isAddToNewTable}
                id={`add-to-new-table-FPAC`}
                onChange={() => setIsAddToNewTable((prev) => !prev)}
              />
            </div>
          </div>
          <SelectColumnDropdown
            selectedOption={selectedOption}
            setSelectedOption={setSelectedOption}
          />
        </div>
        <div className="space-y-4 pt-3">
          <div className="">
            <div className="flex items-center gap-x-3">
              <p className="text-base font-semibold">
                Job Title{" "}
                <span className="text-darkTextGra font-title text-xs font-bold">
                  (Optional)
                </span>
              </p>
            </div>

            <MultiSelectDropdown
              value={includedJobTitles}
              options={[]}
              onChange={(selectedValues) => {
                setIncludedJobTitles(selectedValues as Option[]);
              }}
              placeholder="Add job titles to include"
            />
          </div>
          <AdvancedDetails
            columnIdentifier={columnIdentifier}
            setColumnIdentifier={setColumnIdentifier}
          />
        </div>
        <ConditionalFormula />
      </div>

      <SaveAndRunEnrichment
        isDisabled={!selectedOption?.key}
        apiPayload={{
          columnId: selectedOption?.keyId,
          includedJobTitles: includedJobTitles?.map((item) => item.value),
          isUseOwnKey: false,
          isAddToNewTable,
          dependentTableId,
          columnIdentifier,
        }}
      />
    </>
  );
};

export default FindPeopleAtCompany2;
