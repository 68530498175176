export const processingTypeLabelMap: { [key: string]: string } = {
  enrichment: "Enrichment",
  res_column: "Res Column",
  csv_import: "CSV Import",
  formula: "Formula",
  copy_table: "Copy Table",
  github_star_gazers: "Github Star Gazers",
  remove_duplicate_rows: "Remove Duplicate Rows",
  local_businesses: "Local Businesses",
  sales_nav_import: "Import using Sales Navigator URL",
  track_job_changes: "Track Job Changes",
  linkedin_post_engagements: "Generating leads from social posts",
  auto_pilot_leads_workflow: "Fetching new leads...",
  linkedin_jobs: "Finding new jobs...",
  job_openings: "Job Openings",
  multi_source_jobs: "Finding new jobs...",
  multi_source_job_openings: "Job Openings",
  google_jobs: "Finding new jobs...",
  google_job_openings: "Job Openings",
  persana_jobs: "Finding new jobs...",
  persana_job_openings: "Job Openings",
  add_leads_to_table: "Add Leads to Table",
};
