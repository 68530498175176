import { useEffect, useRef, useState } from "react";
import { Grid, GridItem, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { v4 as uuidv4 } from "uuid";

import {
  TabContext,
  useSearchLeadsStore,
  useSyncPeopleSearchState,
  useTabStore,
} from "@/components/PeopleSearch/store.ts";

import FiltersSection from "./FiltersSection";
import Header from "./Header";
import PeopleSearchResultTable from "./PeopleSearchResultTable";
import Sidebar from "./Sidebar";
import ErrorModal from "./ErrorModal";
import { GridProps } from "@chakra-ui/system";
import { userStore } from "@/stores/user.store";
import userService from "@/services/user.service";
import JoyrideComponent from "../Common/JoyrideComponent";
import CompanySearchResultTable from "../CompanySearch/CompanySearchResultTable";

const PeopleSearchComponent = (props: GridProps) => {
  const uniqueSocketIdRef = useRef(`search-${uuidv4()}`);
  const sidebarActiveTab = useSearchLeadsStore(
    (state) => state.sidebarActiveTab,
  );
  const isShowErrorModal = useSearchLeadsStore(
    (state) => state.isShowErrorModal,
  );
  const searchingFor = useSearchLeadsStore((state) => state.searchingFor);
  const isUpgradeBannerShown = userStore((state) =>
    state.isUpgradeBannerShown(),
  );
  const updateState = useSearchLeadsStore((state) => state.updateState);
  const handleClose = () => {
    updateState({
      isShowErrorModal: false,
    });
  };

  console.log("mySearchingFor 2", searchingFor);

  return (
    <>
      <Grid
        {...props}
        py={0}
        as={TabPanel}
        templateRows="repeat(1, 74px 1fr)"
        templateColumns="repeat(1, 25% 1fr)"
        overflowY={"auto"}
        height={`calc(100vh - 40px - ${isUpgradeBannerShown ? "45px" : "0px"})`}
      >
        <GridItem colSpan={2}>
          <FiltersSection isDisabled={sidebarActiveTab !== "people-search"} />
        </GridItem>
        <GridItem
          maxH={`calc(100vh - 154px - ${isUpgradeBannerShown ? "45px" : "0px"})`}
          minH={`calc(100vh - 154px - ${isUpgradeBannerShown ? "45px" : "0px"})`}
          className="border border-r-[#D0D4DC] bg-[#F5F3FF]"
        >
          <Sidebar searchingFor={searchingFor} />
        </GridItem>
        <GridItem overflow="auto" className="relative flex flex-col">
          {/* Table */}
          <div className="min-h-[400px] px-[16px]">
            {sidebarActiveTab === "people-search" ? (
              <PeopleSearchResultTable
                uniqueSocketId={uniqueSocketIdRef?.current}
              />
            ) : (
              <CompanySearchResultTable />
            )}
          </div>
        </GridItem>
      </Grid>
      <ErrorModal
        isShowErrorModal={!!isShowErrorModal}
        handleClose={handleClose}
      />
    </>
  );
};

const PeopleSearch = () => {
  const currentUser = userStore((state) => state.currentUser);
  const hasSeenPeopleSearchJoyride = currentUser?.joyrideInfo
    ? !!currentUser?.joyrideInfo?.hasSeenPeopleSearchJoyride
    : true;
  const hasSeenTableJoyride = currentUser?.joyrideInfo
    ? !!currentUser?.joyrideInfo?.hasSeenTableJoyride
    : true;
  const activeTab = useTabStore((state) => state.activeTab);
  const tabs = useTabStore((state) => state.tabs);
  const updateTabState = useTabStore((state) => state.updateData);
  useSyncPeopleSearchState();

  const [joyrideState, setJoyrideState] = useState({
    run: false,
    steps: [
      {
        target: "body",
        content: "Welcome! Let's start your journey.",
        placement: "center",
        locale: { skip: "Skip", next: "Next", back: "Back" },
        disableBeacon: true,
      },
      {
        target: ".joyride-select-leads",
        content: "Choose the leads you'd like to import.",
      },
      {
        target: ".joyride-import-leads",
        content:
          "Export to a Persana Table and further enrich with 75+ data points in our platform.",
      },
      {
        target: ".joyride-export-find-email",
        content:
          "Here you can export the selected leads and find their email addresses.",
      },
    ],
    stepIndex: 0,
  });

  const handleJoyrideFinish = async () => {
    setJoyrideState((prevState) => ({ ...prevState, run: false }));
    await userService.updateUser({
      joyrideInfo: { hasSeenPeopleSearchJoyride: true, hasSeenTableJoyride },
    });
    // @ts-ignore
    await queryClient.invalidateQueries(["user-details"]);
  };

  const handleStepChange = (index: number) => {
    setJoyrideState((prevState) => ({ ...prevState, stepIndex: index }));
  };

  useEffect(() => {
    if (!hasSeenPeopleSearchJoyride) {
      setJoyrideState((prevState) => ({ ...prevState, run: true }));
    }
  }, [hasSeenPeopleSearchJoyride]);

  console.log("myTabs", tabs);

  return (
    <>
      <JoyrideComponent
        steps={joyrideState.steps}
        run={false /*joyrideState.run */}
        onFinish={handleJoyrideFinish}
        onStepChange={handleStepChange}
      />
      <Tabs
        variant={"enclosed"}
        size={"md"}
        index={activeTab}
        onChange={(index) => updateTabState({ activeTab: index })}
      >
        <TabContext.Provider value={tabs[activeTab].store}>
          <Header />
        </TabContext.Provider>
        <TabPanels>
          {tabs.map((tab) => (
            <TabContext.Provider key={tab.id} value={tab.store}>
              <PeopleSearchComponent key={tab.id} />
            </TabContext.Provider>
          ))}
        </TabPanels>
      </Tabs>
    </>
  );
};
export default PeopleSearch;
