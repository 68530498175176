import MultiSelectDropdown from "@/components/Common/MultiSelectDropdown";
import { Option } from "@/components/Common/MultiSelectDropdown/types";
import { useMiscData } from "@/hooks/useMisc";

interface Props {
  value: Option;
  onChange: (selectedValues: Option[]) => void;
  label?: string;
  isCreatable?: boolean;
}

const Industries = ({
  value,
  onChange,
  label = "Industries",
  isCreatable = true,
}: Props) => {
  const { data, isLoading } = useMiscData<Option[]>("industries");

  const options = data?.data || [];

  console.log("options", options);

  const handleChange = (selected: Option | Option[]) => {
    const selectedValues = Array.isArray(selected) ? selected : [selected];
    onChange(selectedValues as Option[]);
  };

  return (
    <div className="mb-2">
      <p className="mb-2 text-[14px] font-[600] text-[#1a202c]">{label}</p>
      <MultiSelectDropdown
        placeholder={`e.g. ${options[0]?.label}`}
        options={options}
        onChange={handleChange}
        closeMenuOnSelect
        value={value}
        isLoading={isLoading}
        isCreateAble={isCreatable}
      />
    </div>
  );
};

export default Industries;
