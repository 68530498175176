import {
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
} from "@chakra-ui/react";
import { useMutation } from "@tanstack/react-query";
import { useEffect, useState } from "react";

import AIResultRenderer from "./AIResultRenderer";
import CompanyDetailsRenderer from "./CompanyDetailsRenderer";
import PeopleAtCompanyRenderer from "./PeopleAtCompanyRenderer";
import PersonDetailsRenderer from "./PersonDetailsRenderer";
import ScrapeWebsiteRenderer from "./ScrapeWebsiteRenderer";
import TextRenderer from "./TextRenderer";
import UrlDetailsRenderer from "./UrlDetailsRenderer";

import MainLoader from "@/assets/lottieFiles/main_loader.lottie";
import LottiePlayer from "@/components/Common/LottiePlayer";

import { useTableData } from "@/hooks/useTableData";
import tableService from "@/services/table.service";
import { useModalStore } from "@/stores/modal.store";
import { useTableStore } from "@/stores/table.store";

import type { CellDetails, FormattedDetails } from "@/types/table.types";

const TableDetailsModal = () => {
  const [loading, setLoading] = useState(false);
  const [companyName, setCompanyName] = useState<string>("");
  const [tableDetails, setTableDetails] = useState<FormattedDetails>({});
  const [fetchTableData, setFetchTableData] = useState(false);

  const { isOpenModal, modalData, updateState } = useModalStore();
  const { tableData, rowsData: tableRows } = useTableStore();
  const { isLoading, isRefetching } = useTableData(
    tableData._id,
    fetchTableData,
  );

  const { mutateAsync } = useMutation({
    mutationFn: ({ columnId, rowId }: { columnId: string; rowId: string }) =>
      tableService.getCellData(tableData._id, rowId, columnId),
  });

  const handleClose = () => {
    updateState({ isOpenModal: false, modalData: { modalType: "" } });
    setTableDetails({});
    setCompanyName("");
  };

  useEffect(() => {
    if (!tableRows || !modalData || !tableData || !isOpenModal) return;

    const fetchDetails = async () => {
      setLoading(true);
      const detailsPromises = tableData.columns.map(async (column) => {
        const { _id, name, metaData } = column;
        const rowIndex = modalData?.metaData?.rowIndex;
        const cellValue = tableRows[rowIndex]?.cells?.[_id]?.value;
        let cellDetails = {};

        if (metaData?.event === "modal") {
          try {
            cellDetails = await mutateAsync({
              columnId: _id,
              rowId: tableRows[rowIndex]._id,
            });
          } catch (error) {
            console.error("Error fetching cell details:", error);
            cellDetails = {};
          }
        }

        return { [name]: { value: cellValue, cellDetails, ...column } };
      });

      const details = await Promise.all(detailsPromises);
      const formattedDetails = details.reduce(
        (acc, curr) => ({ ...acc, ...curr }),
        {},
      ) as unknown as FormattedDetails;
      const excludeObjects = [
        "recentLinkedinPost",
        "addLeadToInstantlyCampaign",
        "addLeadToLgm",
        "hubspotCompany",
        "findMobileNumber",
        "twitterDetails",
        "searchPhoneNumber",
        "httpApi",
        "addLeadToApollo",
        "apifyActor",
        "addLeadToSmartleadsCampaign",
      ];

      for (const key in formattedDetails) {
        const { event, enrichmentMetaData } =
          formattedDetails[key]?.metaData || {};
        const enrichmentName = enrichmentMetaData?.enrichmentName || "";

        if (excludeObjects.includes(enrichmentName)) {
          delete formattedDetails[key];
        } else if (enrichmentName === "companyDetails" && event === "modal") {
          setCompanyName(formattedDetails[key].value || "");
        }
      }

      console.log("formattedDetails", formattedDetails);

      setTableDetails(formattedDetails);
      setLoading(false);
    };

    fetchDetails();
  }, [tableRows, modalData, tableData, mutateAsync, isOpenModal]);

  useEffect(() => {
    isOpenModal && setFetchTableData(true);
  }, [isOpenModal]);

  const renderContent = (value: CellDetails) => {
    const { event, jobName, enrichmentMetaData } = value.metaData || {};
    const enrichmentName = enrichmentMetaData?.enrichmentName || "";

    if (event === "modal") {
      switch (enrichmentName) {
        case "persanaAgent2":
        case "persanaAgent3":
          console.log("myValue", value);

          return <AIResultRenderer value={value} />;
        case "jobOpenings":
        case "companyNews":
        case "companyTechnologies":
        case "googleSearch":
          return (
            <UrlDetailsRenderer
              cellDetails={value.cellDetails}
              jobName={jobName}
            />
          );
        default:
          return <TextRenderer value={value.value} />;
      }
    } else {
      switch (enrichmentName) {
        case "scrapeWebsite":
          return <ScrapeWebsiteRenderer value={value.value} />;
        default:
          return <TextRenderer value={value.value} />;
      }
    }
  };

  return (
    <Modal
      isCentered
      onClose={handleClose}
      isOpen={isOpenModal && modalData.modalType === "table-details"}
      motionPreset="scale"
      size="7xl"
    >
      <ModalOverlay bg="rgba(0, 0, 0, 0.1)" />
      <ModalContent className="rounded-[20px]">
        <ModalHeader className="text-center">
          {`${companyName || "Table"} Row Details`}
        </ModalHeader>
        <ModalCloseButton />
        <ModalBody>
          {loading || isLoading || isRefetching ? (
            <div className="flex h-[90vh] w-full flex-col items-center justify-center">
              <LottiePlayer src={MainLoader} />
              <p className="gradient-text text-2xl font-medium text-primaryPurple">
                Firing up Persana's Brain
              </p>
            </div>
          ) : (
            <div className="container mx-auto min-h-[90vh]">
              {Object.entries(tableDetails).map(([key, value]) => {
                const { metaData } = value || {};
                const enrichmentName =
                  metaData?.enrichmentMetaData?.enrichmentName || "";
                const event = metaData?.event || "";
                if (!value.value) return null;

                return (
                  <div key={key}>
                    {event === "modal" ? (
                      enrichmentName === "companyDetails" ? (
                        <CompanyDetailsRenderer details={value} />
                      ) : enrichmentName === "linkedInDetails" ? (
                        <PersonDetailsRenderer details={value} />
                      ) : enrichmentName.includes("findPeopleAtCompany") ? (
                        <PeopleAtCompanyRenderer details={value} />
                      ) : (
                        <div className="mb-4 rounded-lg bg-white p-4 shadow-lg break-words overflow-hidden w-full">
                          <p className="rounded-md bg-[#F8F0FF] px-2 py-1 text-[14px] font-bold text-textPurple">
                            {key}
                          </p>
                          {renderContent(value)}
                        </div>
                      )
                    ) : (
                      <div className="mb-4 rounded-lg bg-white p-4 shadow-lg break-words overflow-hidden w-full">
                        <p className="rounded-md bg-[#F8F0FF] px-2 py-1 text-[14px] font-bold text-textPurple">
                          {key}
                        </p>
                        {renderContent(value)}
                      </div>
                    )}
                  </div>
                );
              })}
            </div>
          )}
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};

export default TableDetailsModal;
